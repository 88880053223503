
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { RunId, TarTaskSource } from '@/store/types'
import InlineMinimalRepoNameDisplay from '@/components/misc/InlineMinimalRepoDisplay.vue'
import { mdiScaleBalance } from '@mdi/js'

@Component({
  components: {
    'inline-minimal-repo-display': InlineMinimalRepoNameDisplay
  }
})
export default class TarOverview extends Vue {
  @Prop()
  private readonly tarSource!: TarTaskSource

  @Prop({ default: null })
  private readonly runId!: RunId | null

  private get compareRunLocation() {
    if (!this.runId) {
      return null
    }
    return {
      name: 'search',
      params: {
        runId: this.runId
      }
    }
  }

  // ICONS
  private compareIcon = mdiScaleBalance
}
