
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop, Watch } from 'vue-property-decorator'
import {
  Dimension,
  DimensionDifference,
  DimensionInterpretation,
  Measurement,
  MeasurementError,
  MeasurementSuccess
} from '@/store/types'
import MeasurementValueDisplay from '@/components/rundetail/MeasurementValueDisplay.vue'
import { safeConvertAnsi } from '@/util/Texts'
import { vxm } from '@/store'

const numberFormat: Intl.NumberFormat = new Intl.NumberFormat(
  new Intl.NumberFormat().resolvedOptions().locale,
  { maximumFractionDigits: 3, notation: 'compact' }
)

class Item {
  readonly benchmark: string
  readonly metric: string
  readonly unit: string
  readonly value?: number
  readonly standardDeviation?: number
  readonly standardDeviationPercent?: number
  readonly change?: number
  readonly changePercent?: number
  readonly changeColor: string
  readonly error?: string

  constructor(
    benchmark: string,
    metric: string,
    unit: string,
    interpretation: DimensionInterpretation,
    value?: number,
    standardDeviation?: number,
    standardDeviationPercent?: number,
    change?: number,
    changePercent?: number,
    error?: string
  ) {
    this.benchmark = benchmark
    this.metric = metric
    this.unit = unit
    this.value = value
    this.standardDeviation = standardDeviation
    this.standardDeviationPercent = standardDeviationPercent
    this.change = change
    this.changePercent = changePercent
    this.error = error
    this.changeColor = this.computeChangeColor(interpretation, change)
  }

  get valueFormatted() {
    return this.formatNumber(this.value)
  }

  get standardDeviationFormatted() {
    return this.formatNumber(this.standardDeviation)
  }

  get standardDeviationPercentFormatted() {
    return this.formatPercent(this.standardDeviationPercent)
  }

  get changeFormatted() {
    return this.formatNumber(this.change)
  }

  get changePercentFormatted() {
    return this.formatPercent(this.changePercent)
  }

  private formatNumber(number?: number): string | undefined {
    if (number === undefined) {
      return undefined
    }
    if (Math.abs(number) === 0) {
      return '0'
    }
    return numberFormat.format(number)
  }

  private formatPercent(number?: number): string | undefined {
    if (number === undefined) {
      return this.formatNumber(number)
    }
    return this.formatNumber(number * 100) + '%'
  }

  private computeChangeColor(
    interpretation: DimensionInterpretation,
    change?: number
  ): string {
    if (change === undefined || Math.abs(change) === 0 || isNaN(change)) {
      return ''
    }

    if (interpretation === 'NEUTRAL') {
      return ''
    }

    let bad = false
    if (interpretation === 'LESS_IS_BETTER') {
      bad = change > 0
    } else if (interpretation === 'MORE_IS_BETTER') {
      bad = change < 0
    }

    if (bad) {
      return 'var(--v-warning-base)'
    }
    return 'var(--v-success-base)'
  }
}

@Component({
  components: {
    'measurement-value': MeasurementValueDisplay
  }
})
export default class MeasurementsDisplay extends Vue {
  @Prop()
  private readonly measurements!: Measurement[]

  @Prop()
  private readonly differences?: DimensionDifference[]

  private showDetailErrorDialog: boolean = false
  private safeDetailErrorDialogMessage: string = ''

  private get headerFormats() {
    return [
      {
        slotName: 'item.change',
        displayField: 'changeFormatted',
        tooltip: 'No unambiguous parent commit found',
        colored: true
      },
      {
        slotName: 'item.changePercent',
        displayField: 'changePercentFormatted',
        tooltip: (item: Item) => {
          if (!item.change) {
            return 'No unambiguous parent commit found'
          }
          return "The old value was zero. I can't divide by it :/"
        },
        colored: true
      },
      {
        slotName: 'item.standardDeviation',
        displayField: 'standardDeviationFormatted',
        tooltip:
          'Not applicable as the benchmark script did not report enough values',
        colored: false
      },
      {
        slotName: 'item.standardDeviationPercent',
        displayField: 'standardDeviationPercentFormatted',
        tooltip:
          'Not applicable as the benchmark script did not report enough values',
        colored: false
      }
    ]
  }

  private get headers() {
    return [
      { text: 'Benchmark', value: 'benchmark', align: 'left' },
      { text: 'Metric', value: 'metric', align: 'left' },
      { text: 'Unit', value: 'unit', align: 'left' },
      { text: 'Value', value: 'value', align: 'right' },
      {
        text: 'Stddev',
        value: 'standardDeviation',
        align: 'right'
      },
      {
        text: 'Stddev %',
        value: 'standardDeviationPercent',
        align: 'right'
      },
      { text: 'Change', value: 'change', align: 'right' },
      { text: 'Change %', value: 'changePercent', align: 'right' }
    ]
  }

  private get items(): Item[] {
    return this.measurements.map(it =>
      it instanceof MeasurementSuccess
        ? this.successToItem(it)
        : this.errorToItem(it)
    )
  }

  private successToItem(measurement: MeasurementSuccess): Item {
    const difference = this.differenceForDimension(measurement.dimension)
    return new Item(
      measurement.dimension.benchmark,
      measurement.dimension.metric,
      measurement.dimension.unit,
      measurement.dimension.interpretation,
      measurement.value,
      measurement.stddev,
      measurement.stddevPercent,
      difference ? difference.absDiff : undefined,
      difference ? difference.relDiff : undefined
    )
  }

  private differenceForDimension(
    dimension: Dimension
  ): DimensionDifference | undefined {
    return this.differencesByDimension[dimension.toString()]
  }

  private get differencesByDimension(): {
    [asString: string]: DimensionDifference
  } {
    if (!this.differences) {
      return {}
    }
    const differencesByDimension: {
      [asString: string]: DimensionDifference
    } = {}
    this.differences.forEach(it => {
      differencesByDimension[it.dimension.toString()] = it
    })
    return differencesByDimension
  }

  private errorToItem(measurementError: MeasurementError): Item {
    return new Item(
      measurementError.dimension.benchmark,
      measurementError.dimension.metric,
      measurementError.dimension.unit,
      measurementError.dimension.interpretation,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      measurementError.error
    )
  }

  private formatErrorShorthand(error: string) {
    const MAX_ERROR_LENGTH = 30
    if (error.length < MAX_ERROR_LENGTH) {
      return error
    }
    return error.substring(0, MAX_ERROR_LENGTH) + '…'
  }

  private displayErrorDetail(item: Item) {
    if (!item.error) {
      return ''
    }
    this.safeDetailErrorDialogMessage = safeConvertAnsi(item.error)
    this.showDetailErrorDialog = true
  }

  // noinspection JSUnusedLocalSymbols (Used by the watcher below)
  private get darkThemeSelected() {
    return vxm.userModule.darkThemeSelected
  }

  @Watch('darkThemeSelected')
  private onDarkThemeSelectionChanged() {
    // The ANSI conversion needs to be redone
    this.$forceUpdate()
  }

  private rowClicked(item: Item) {
    const currentSelection = document.getSelection()
    if (currentSelection && currentSelection.toString()) {
      return
    }
    const measurement = this.measurements.find(
      it =>
        it.dimension.benchmark === item.benchmark &&
        it.dimension.metric === item.metric
    )!

    this.$emit('dimension-clicked', measurement.dimension)
  }
}
