
import Vue from 'vue'
import Component from 'vue-class-component'
import { Worker } from '@/store/types'
import { Prop } from 'vue-property-decorator'

@Component
export default class WorkerOverview extends Vue {
  @Prop()
  private workers!: Worker[]

  private formatWorkerInformation(worker: Worker) {
    if (!worker.info) {
      return 'No data known :/'
    }
    return worker.info
  }
}
