
import Vue from 'vue'
import Component from 'vue-class-component'
import { mdiLinkVariantPlus } from '@mdi/js'
import { copyToClipboard } from '@/util/Clipboards'
import { PermanentLinkOptions } from '@/store/modules/detailGraphStore'
import { Prop, Watch } from 'vue-property-decorator'

export type Option = {
  label: string
  selectable: boolean
  unselectableMessage: string
  key: keyof PermanentLinkOptions
}

@Component
export default class ShareGraphLinkDialog extends Vue {
  private dialogOpen: boolean = false
  private options: PermanentLinkOptions = {
    includeYZoom: true,
    includeXZoom: true,
    includeDataRestrictions: true
  }

  @Prop()
  private readonly linkGenerator!: (options: PermanentLinkOptions) => string

  @Prop()
  private readonly shareOptions!: Option[]

  private get permanentLinkUrl() {
    return this.linkGenerator(this.options)
  }

  private copyPermanentLink() {
    copyToClipboard(this.permanentLinkUrl, this.$globalSnackbar)
  }

  @Watch('dialogOpen')
  private onDialogVisibilityChange() {
    if (this.dialogOpen) {
      this.options = {
        includeDataRestrictions: true,
        includeXZoom: true,
        includeYZoom: true
      }
    }
  }

  // ICONS
  private permanentLinkIcon = mdiLinkVariantPlus
}
