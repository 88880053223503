
import Vue from 'vue'
import Component from 'vue-class-component'
import { Dimension } from '@/store/types'
import { Model, Prop } from 'vue-property-decorator'

class Item {
  readonly text: string
  readonly value: string
  readonly dimension: Dimension

  constructor(dimension: Dimension) {
    this.text = dimension.toString()
    this.value = dimension.toString()
    this.dimension = dimension
  }
}

@Component
export default class ComparisonDimensionSelector extends Vue {
  @Prop()
  private readonly possibleDimensions!: Dimension[]

  @Model('input')
  private readonly selectedDimension!: Dimension

  @Prop()
  private readonly errorMessage!: string | null

  private get items() {
    return this.possibleDimensions.map(dimension => new Item(dimension))
  }

  private get selectedItem() {
    if (!this.selectedDimension) {
      return null
    }
    if (
      !this.possibleDimensions.find(it => it.equals(this.selectedDimension))
    ) {
      return null
    }
    return (
      this.items.find(it => it.dimension.equals(this.selectedDimension)) || null
    )
  }

  private selectDimension(value: Item) {
    if (!value) {
      this.$emit('input', null)
    } else {
      this.$emit('input', value.dimension)
    }
  }
}
