
import Vue from 'vue'
import { Component, Watch } from 'vue-property-decorator'
import { vxm } from '@/store'
import RepoSelectionComponent from './RepoSelectionComponent.vue'

@Component({
  components: {
    'repo-selection': RepoSelectionComponent
  }
})
export default class LoginDialog extends Vue {
  private loading: boolean = false
  private repoId: string = ''
  private token: string = ''

  private formValid: boolean = false
  private dialogOpen: boolean = false

  @Watch('dialogOpen')
  private onOpened(opened: boolean) {
    if (!opened) {
      this.token = ''
      this.repoId = ''
    }
  }

  private nonEmptyToken(input: string): boolean | string {
    return input.length > 0 ? true : 'This field must not be empty!'
  }

  private login() {
    if (!this.formValid) {
      return
    }

    this.loading = true

    vxm.userModule
      .logIn(this.token)
      .then(() => (this.dialogOpen = false))
      .finally(() => (this.loading = false))
  }
}
