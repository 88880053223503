import { render, staticRenderFns } from "./GithubTokenConfiguration.vue?vue&type=template&id=11ca6754&scoped=true&"
import script from "./GithubTokenConfiguration.vue?vue&type=script&lang=ts&"
export * from "./GithubTokenConfiguration.vue?vue&type=script&lang=ts&"
import style0 from "./GithubTokenConfiguration.vue?vue&type=style&index=0&id=11ca6754&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "11ca6754",
  null
  
)

export default component.exports