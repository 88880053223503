
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { SearchItemCommit } from '@/store/types'
import { RawLocation } from 'vue-router'
import { formatDate, formatDateUTC } from '@/util/Times'
import TextChip from '@/components/misc/TextChip.vue'
import InlineMinimalRepoDisplay from '@/components/misc/InlineMinimalRepoDisplay.vue'
import { mdiSourceCommit } from '@mdi/js'

@Component({
  components: {
    'repo-display': InlineMinimalRepoDisplay,
    TextChip
  }
})
export default class SearchResultCommit extends Vue {
  @Prop()
  private readonly item!: SearchItemCommit

  private get commitLinkLocation(): RawLocation {
    return {
      name: 'run-detail',
      params: { first: this.item.repoId, second: this.item.hash }
    }
  }

  private get showCommitter() {
    return this.item.committer !== this.item.author
  }

  private formatDate(date: Date) {
    return formatDate(date)
  }

  private formatDateUTC(date: Date) {
    return formatDateUTC(date)
  }

  private get hasRun() {
    return this.item.hasRun
  }

  private readonly commitIcon = mdiSourceCommit
}
