
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { CommitDescription } from '@/store/types'
import { mdiArrowRight, mdiArrowLeft } from '@mdi/js'

@Component
export default class CommitNavigationButton extends Vue {
  @Prop()
  private commitDescription!: CommitDescription

  @Prop({ default: 'PARENT' })
  private type!: 'PARENT' | 'CHILD'

  @Prop({ default: true })
  private tracked!: boolean

  // ICONS
  private parentCommitIcon = mdiArrowLeft
  private childCommitIcon = mdiArrowRight
}
