
import Vue from 'vue'
import Component from 'vue-class-component'
import NotFound404 from '@/views/NotFound404.vue'
import {
  Commit,
  CommitTaskSource,
  TarTaskSource,
  TaskSource
} from '@/store/types'
import { Watch } from 'vue-property-decorator'
import { vxm } from '@/store'
import CommitDetail from '@/components/rundetail/CommitDetail.vue'
import TaskRunnerOutput from '@/components/queue/TaskRunnerOutput.vue'
import InlineMinimalRepoDisplay from '@/components/misc/InlineMinimalRepoDisplay.vue'
import { TaskInfo } from '@/store/modules/queueStore'
import { formatDurationShort } from '@/util/Times'
import TarOverview from '@/components/overviews/TarOverview.vue'

@Component({
  components: {
    'tar-overview': TarOverview,
    'inline-minimal-repo-display': InlineMinimalRepoDisplay,
    'task-runner-output': TaskRunnerOutput,
    'page-404': NotFound404,
    'commit-detail': CommitDetail
  }
})
export default class TaskDetailView extends Vue {
  private show404: boolean = false

  private taskInfo: TaskInfo | null = null
  private commit: Commit | null = null
  private nudgeToRunDetail: boolean = false

  private timerIds: number[] = []
  private queuedSinceText: string | null = null

  private get taskId() {
    return this.$route.params.taskId
  }

  private get tarSource() {
    if (!this.taskInfo || !this.taskInfo.task) {
      return null
    }
    if (this.taskInfo.task.source instanceof TarTaskSource) {
      return this.taskInfo.task.source
    }
    return null
  }

  @Watch('taskId')
  private async update() {
    this.show404 = false

    const newInfo = await vxm.queueModule.fetchTaskInfo(this.taskId)

    if (!newInfo) {
      await this.handleTaskNotFound()
      return
    }

    this.taskInfo = newInfo
    await this.handleSource(this.taskInfo.task.source)
  }

  private async handleSource(source: TaskSource) {
    if (source instanceof CommitTaskSource) {
      this.commit = await vxm.commitDetailComparisonModule.fetchCommit({
        repoId: source.commitDescription.repoId,
        commitHash: source.commitDescription.hash
      })
    }
  }

  private async handleTaskNotFound() {
    try {
      const run = await vxm.commitDetailComparisonModule.fetchRun(this.taskId)
      this.nudgeToRunDetail = true
      await this.handleSource(run.run.source)
    } catch (e) {
      this.show404 = true
    }
  }

  private loadingOutputFailed() {
    if (this.taskInfo === null) {
      this.handleTaskNotFound()
    }
  }

  private updateDuration() {
    if (!this.taskInfo) {
      this.queuedSinceText = null
      return
    }

    if (this.taskInfo.runningSince) {
      this.queuedSinceText =
        'running for ' +
        formatDurationShort(this.taskInfo.runningSince, new Date())
    } else {
      this.queuedSinceText =
        'queued for  ' +
        formatDurationShort(this.taskInfo.task.since, new Date())
    }
  }

  private navigateToRespectiveRun() {
    this.$router.push({
      name: 'run-detail',
      params: {
        first: this.taskId
      }
    })
  }

  private mounted() {
    const updateTaskTimer = setInterval(() => {
      this.update()
    }, 10_000)
    const updateDurationTimer = setInterval(() => this.updateDuration(), 1000)

    this.timerIds = [updateTaskTimer, updateDurationTimer]
    this.update()
  }

  private destroyed() {
    this.timerIds.forEach(clearInterval)
  }
}
