var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-tooltip',{attrs:{"right":_vm.type === 'PARENT',"left":_vm.type === 'CHILD'},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-btn',_vm._g({staticClass:"d-flex commit-navigation-button",class:_vm.tracked ? '' : 'text--disabled',attrs:{"text":"","outlined":"","to":{
        name: 'run-detail',
        params: {
          first: _vm.commitDescription.repoId,
          second: _vm.commitDescription.hash
        }
      }}},on),[(_vm.type === 'PARENT')?_c('v-icon',{staticClass:"button-icon",attrs:{"left":""}},[_vm._v(" "+_vm._s(_vm.parentCommitIcon)+" ")]):_vm._e(),_c('span',{staticClass:"button-text"},[_vm._v(_vm._s(_vm.commitDescription.summary))]),(_vm.type === 'CHILD')?_c('v-icon',{staticClass:"button-icon",attrs:{"right":""}},[_vm._v(" "+_vm._s(_vm.childCommitIcon)+" ")]):_vm._e()],1)]}}])},[(!_vm.tracked)?_c('span',{staticClass:"font-weight-bold"},[_vm._v("(On untracked branch)")]):_vm._e(),_vm._v(" "+_vm._s(_vm.commitDescription.hash)+" by "+_vm._s(_vm.commitDescription.author)+" ")])
}
var staticRenderFns = []

export { render, staticRenderFns }