
import Vue from 'vue'
import Component from 'vue-class-component'
import GraphDimensionSelector from '@/components/repodetail/GraphDimensionSelector.vue'
import { Prop } from 'vue-property-decorator'
import { Dimension } from '@/store/types'

@Component({
  components: {
    GraphDimensionSelector
  }
})
export default class ExpandableDimensionSelection extends Vue {
  private dimensionSelectionInFullscreen = false

  @Prop()
  private readonly allDimensions!: Dimension[]

  @Prop()
  private readonly selectedDimensions!: Dimension[]

  @Prop({ default: 'matrix' })
  private readonly selectorType!: 'tree' | 'matrix'

  private updateSelectedDimensions(newDimensions: Dimension[]) {
    this.$emit('update:selectedDimensions', newDimensions)
  }

  private updateSelectorType(type: 'tree' | 'matrix') {
    this.$emit('update:selectorType', type)
  }
}
