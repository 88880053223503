
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { Commit, RunId, TrackedCommitDescription } from '@/store/types'
import { formatDateUTC, formatDate } from '@/util/Times'
import InlineMinimalRepoNameDisplay from '../misc/InlineMinimalRepoDisplay.vue'
import CommitBenchmarkActions from '../runs/CommitBenchmarkActions.vue'
import CommitNavigationButton from './CommitNavigationButton.vue'
import { mdiCompassOffOutline } from '@mdi/js'

class NavigationTarget {
  readonly parent: TrackedCommitDescription | null
  readonly child: TrackedCommitDescription | null

  constructor(
    parent: TrackedCommitDescription | null,
    child: TrackedCommitDescription | null
  ) {
    this.parent = parent
    this.child = child
  }
}

@Component({
  components: {
    'commit-benchmark-actions': CommitBenchmarkActions,
    'inline-repo-display': InlineMinimalRepoNameDisplay,
    'commit-navigation-button': CommitNavigationButton
  }
})
export default class CommitDetail extends Vue {
  @Prop()
  private commit!: Commit

  @Prop({ default: null })
  private readonly selectedRun!: RunId | null

  private formatDate(date: Date) {
    return formatDate(date)
  }

  private formatDateUTC(date: Date) {
    return formatDateUTC(date)
  }

  private get navigationTargets(): NavigationTarget[] {
    const targets: NavigationTarget[] = []
    const mutualItems = Math.min(
      this.commit.parents.length,
      this.commit.children.length
    )
    let i = 0
    for (; i < mutualItems; i++) {
      targets.push(
        new NavigationTarget(this.commit.parents[i], this.commit.children[i])
      )
    }

    if (i === this.commit.parents.length) {
      for (; i < this.commit.children.length; i++) {
        targets.push(new NavigationTarget(null, this.commit.children[i]))
      }
    } else {
      for (; i < this.commit.parents.length; i++) {
        targets.push(new NavigationTarget(this.commit.parents[i], null))
      }
    }

    return targets
  }

  private readonly untrackedIcon = mdiCompassOffOutline
}
