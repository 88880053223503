
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { SearchItem, SearchItemCommit, SearchItemRun } from '@/store/types'
import SearchResultCommit from '@/components/search/SearchResultCommit.vue'
import SearchResultRun from '@/components/search/SearchResultRun.vue'
import { mdiScaleBalance } from '@mdi/js'
import SearchResultBranch from '@/components/search/SearchResultBranch.vue'

class DisplayedItem {
  readonly id: string
  readonly type: string
  readonly item: SearchItem

  constructor(item: SearchItem) {
    this.item = item

    if (item instanceof SearchItemCommit) {
      this.type = 'commit'
      this.id = item.repoId + item.hash
    } else if (item instanceof SearchItemRun) {
      this.type = item.tarDescription ? 'tar' : 'run'
      this.id = item.id
    } else {
      this.type = 'branch'
      this.id = item.repoId + item.hash
    }
  }
}

@Component({
  components: {
    commit: SearchResultCommit,
    branch: SearchResultBranch,
    run: SearchResultRun,
    tar: SearchResultRun
  }
})
export default class SearchResultList extends Vue {
  @Prop({ default: () => [] })
  private readonly items!: SearchItem[]

  private get displayedItems(): DisplayedItem[] {
    return this.items.map(it => new DisplayedItem(it))
  }

  private readonly compareIcon = mdiScaleBalance
}
