
import Vue from 'vue'
import Component from 'vue-class-component'
import { mdiCalendar, mdiLock, mdiLockOpenVariant } from '@mdi/js'
import { Prop } from 'vue-property-decorator'

type TimeField = {
  ref: string
  value: string
  role: 'start' | 'end'
  opened: boolean
  saveFunction: (field: TimeField, value: string) => void
  rules: ((input: string) => string | boolean)[]
  allowedDates?: (input: string) => boolean
}

@Component
export default class GraphTimespanControls extends Vue {
  // <!--<editor-fold desc="Fields">-->
  /**
   * The value of the "duration" input field. Not applied until saveDuration is called.
   */
  private temporaryDuration: string = '' + this.duration

  private dateLocked: 'start' | 'end' | 'neither' = 'end'
  private readonly timeFields: TimeField[] = [
    {
      ref: 'startDateMenu',
      value: this.startTimeString,
      role: 'start',
      opened: false,
      saveFunction: this.saveStartTime,
      allowedDates: this.allowedDatesStart,
      rules: []
    },
    {
      ref: 'endDateMenu',
      value: this.endTimeString,
      role: 'end',
      opened: false,
      saveFunction: this.saveEndTime,
      allowedDates: this.allowedDatesEnd,
      rules: [this.ruleStopAfterStart]
    }
  ]
  // <!--</editor-fold>-->

  // <!--<editor-fold desc="Props">-->
  @Prop()
  private readonly endTime!: Date

  @Prop()
  private readonly startTime!: Date
  // <!--</editor-fold>-->

  // <!--<editor-fold desc="Save helpers to bind to correct this">-->
  private allowedDatesStart(dateString: string) {
    return new Date(dateString) <= this.endTime
  }

  private allowedDatesEnd(dateString: string) {
    return new Date(dateString) >= this.startTime
  }

  private saveStartTime(field: TimeField, value: string) {
    this.saveMenu(field.ref, value)
    this.$emit('update:startTime', new Date(value))
    this.$emit('reload-graph-data')
  }

  private saveEndTime(field: TimeField, value: string) {
    this.saveMenu(field.ref, value)
    this.$emit('update:endTime', new Date(value))
    this.$emit('reload-graph-data')
  }

  private saveMenu(ref: string, value: string) {
    let field: any
    const readRef = this.$refs[ref]

    if (readRef instanceof Vue) {
      field = readRef
    } else if (Array.isArray(readRef)) {
      field = readRef[0]
    }
    field.save(value)
  }
  // <!--</editor-fold>-->

  // <!--<editor-fold desc="Duration">-->
  private get duration(): number {
    const timeDiff = this.endTime.getTime() - this.startTime.getTime()

    return Math.ceil(timeDiff / (1000 * 3600 * 24)) // round up to days
  }

  private saveDuration() {
    const duration = parseInt(this.temporaryDuration)

    if (isNaN(duration)) {
      return
    }

    if (this.duration === duration) {
      // Do not fetch everything again
      return
    }

    const durationAsMillis = duration * 1000 * 60 * 60 * 24 // ms * minutes * hours * days

    if (this.dateLocked === 'start') {
      this.$emit(
        'update:endTime',
        new Date(this.startTime.getTime() + durationAsMillis)
      )
    } else {
      this.$emit(
        'update:startTime',
        new Date(this.endTime.getTime() - durationAsMillis)
      )
    }

    this.$emit('reload-graph-data')
  }
  // <!--</editor-fold>-->

  // <!--<editor-fold desc="Start/Stop time">-->
  private get startTimeString(): string {
    return this.startTime.toISOString().substring(0, 10)
  }

  private get endTimeString(): string {
    return this.endTime.toISOString().substring(0, 10)
  }
  // <!--</editor-fold>-->

  private lockDates(date: 'start' | 'end'): void {
    if (this.dateLocked === 'neither') {
      this.dateLocked = date
    } else if ((this.dateLocked = date)) {
      this.dateLocked = 'neither'
    } else {
      this.dateLocked = this.dateLocked === 'start' ? 'end' : 'start'
    }
  }

  // <!--<editor-fold desc="Rules">-->
  private ruleStopAfterStart(input: string): boolean | string {
    return this.startTime <= new Date(input)
      ? true
      : 'You have to select a date after the first one!'
  }

  private ruleIsNumber(input: string): string | boolean {
    return isNaN(parseInt(input)) ? 'Please enter a number' : true
  }
  // <!--</editor-fold>-->

  private get today() {
    return new Date().toISOString().substr(0, 10)
  }

  // ============== ICONS ==============
  private dateIcon = mdiCalendar
  private openLock = mdiLockOpenVariant
  private lock = mdiLock
  // ==============       ==============
}
