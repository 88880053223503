
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { RawLocation } from 'vue-router'
import TextChip from '@/components/misc/TextChip.vue'
import InlineMinimalRepoDisplay from '@/components/misc/InlineMinimalRepoDisplay.vue'
import { mdiFolderZipOutline, mdiRunFast } from '@mdi/js'
import { SearchItemRun } from '@/store/types'
import { formatDate, formatDurationHuman } from '@/util/Times'

@Component({
  components: {
    'repo-display': InlineMinimalRepoDisplay,
    TextChip
  }
})
export default class SearchResultRun extends Vue {
  @Prop()
  private readonly item!: SearchItemRun

  private get linkLocation(): RawLocation {
    return {
      name: 'run-detail',
      params: { first: this.item.id }
    }
  }

  private get startTimeString() {
    return formatDate(this.item.startTime)
  }

  private get durationString() {
    return formatDurationHuman(this.item.startTime, this.item.stopTime)
  }

  private get description() {
    return this.item.tarDescription || this.item.commitSummary
  }

  private get icon() {
    return this.item.tarDescription ? this.tarIcon : this.runIcon
  }

  private readonly runIcon = mdiRunFast
  private readonly tarIcon = mdiFolderZipOutline
}
