
import Vue from 'vue'
import Component from 'vue-class-component'
import { Watch } from 'vue-property-decorator'
import { vxm } from '@/store'
import { RunDescriptionWithDifferences, RunDescription } from '@/store/types'
import MultipleRunOverview from '@/components/overviews/MultipleRunOverview.vue'

@Component({
  components: {
    'multiple-run-overview': MultipleRunOverview
  }
})
export default class Home extends Vue {
  private recentAmount: number = 10
  private recentSignificantAmount: number = 10

  get recent(): RunDescription[] {
    return vxm.newsModule.recentRuns
  }

  get recentSignificant(): RunDescriptionWithDifferences[] {
    return vxm.newsModule.recentSignificantRuns
  }

  @Watch('recentAmount')
  private fetchRecent() {
    vxm.newsModule.fetchRecentRuns(this.recentAmount)
  }

  @Watch('recentSignificantAmount')
  private fetchRecentSignificant() {
    vxm.newsModule.fetchRecentSignificantRuns(this.recentSignificantAmount)
  }

  created(): void {
    this.fetchRecent()
    this.fetchRecentSignificant()
  }
}
