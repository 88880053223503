
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { vxm } from '@/store'
import { Repo } from '@/store/types'

@Component
export default class InlineMinimalRepoNameDisplay extends Vue {
  @Prop()
  private repoId!: string

  private get repo() {
    return (
      vxm.repoModule.repoById(this.repoId) ||
      new Repo('Placeholder', 'Not loaded yet :/', [], [], '', undefined)
    )
  }
}
