import { render, staticRenderFns } from "./RepoBaseInformation.vue?vue&type=template&id=5c4de881&scoped=true&"
import script from "./RepoBaseInformation.vue?vue&type=script&lang=ts&"
export * from "./RepoBaseInformation.vue?vue&type=script&lang=ts&"
import style1 from "./RepoBaseInformation.vue?vue&type=style&index=1&id=5c4de881&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5c4de881",
  null
  
)

export default component.exports