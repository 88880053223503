
import Vue from 'vue'
import Component from 'vue-class-component'
import { mdiInvertColors } from '@mdi/js'
import { vxm } from '@/store'

@Component
export default class ThemeSelector extends Vue {
  private toggleDarkTheme() {
    this.$emit('use-dark-theme', !this.isDarkTheme)
  }

  private get isDarkTheme(): boolean {
    return vxm.userModule.darkThemeSelected
  }

  // ICONS
  private darkThemeIcon = mdiInvertColors
}
