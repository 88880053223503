
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'

export type TokenState = 'delete' | 'modify' | 'unchanged'

@Component
export default class GithubTokenConfiguration extends Vue {
  private tokenStateImpl: TokenState = 'unchanged'

  @Prop()
  private readonly hasToken!: string

  @Prop()
  private readonly newToken!: string

  private get tokenState() {
    return this.tokenStateImpl
  }

  private set tokenState(state: TokenState) {
    this.tokenStateImpl = state

    this.$emit('update:tokenState', state)
  }

  private setNewToken(token: string) {
    this.$emit('update:newToken', token)
  }

  private notEmpty(input: string): boolean | string {
    return input.trim().length > 0 ? true : 'This field must not be empty'
  }
}
